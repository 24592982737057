import BillingDetails from "../components/pageSection/billingDetailsSection";
import ShippingAddress from "../components/pageSection/shippingAddressSection/index";
import OrderSummery from "../components/pageSection/orderSummerySection/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../components/form/Button";
import Radio from "../components/form/Radio";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { newPlaceOrder } from "../services/userService";

export default function Index() {
  const { data: cartLists, isLoaded: cartLoaded } = useSelector(
    (state) => state?.cart?.cartData
  );
  const [paymentMethod, setPaymentMethod] = useState(true);
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const [loaded, setLoaded] = useState(false);

  const [payload, setPayload] = useState({
    items: [],
    totalPrice: 0,
    // paymentVia: "online",
    paymentMethod: "cash",
    shippingAddress: {},
    billingAddress: null,
    isShippingAddressSame: true,
    isMailingAddressSame: true,
    deliveryType: "in-store",
  });

  // console.log(payload, "payloadpayload");

  const validateAndNavigate = () => {
    if (!payload?.billingAddress?.address1) {
      toast.error("Billing address is required.");
      return;
    }
    if (!payload?.billingAddress?.address2) {
      toast.error("Billing address is required.");
      return;
    }
    if (!payload?.billingAddress?.state) {
      toast.error("Billing state is required.");
      return;
    }
    if (!payload?.billingAddress?.city) {
      toast.error("Billing city is required.");
      return;
    }
    if (!payload?.billingAddress?.zipcode) {
      toast.error("Billing pin is required.");
      return;
    }
    if (!payload?.isShippingAddressSame) {
      if (!payload?.shippingAddress?.address1) {
        toast.error("Shipping  address is required.");
        return;
      }
      if (!payload?.shippingAddress?.address2) {
        toast.error("Shipping  address is required.");
        return;
      }
      if (!payload?.shippingAddress?.state) {
        toast.error("Shipping  state is required.");
        return;
      }
      if (!payload?.shippingAddress?.city) {
        toast.error("Shipping  city is required.");
        return;
      }
      if (!payload?.shippingAddress?.zipcode) {
        toast.error("Shipping  pin is required.");
        return;
      }
    }
    if (payload?.items.length <= 0) {
      toast.error("Please Add to cart any product");
      return;
    }
    if (!payload?.totalPrice) {
      toast.error("Please Add to cart any product");
      return;
    }

    if (isLogin) {
      setLoaded(true);
      try {
        const res = newPlaceOrder({
          ...payload,
        });
        console.log(...payload, "srstxsft");

        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.info(res?.data?.message);
          navigate("/user-orders");
        } else {
          toast.info(
            res?.data?.message || res?.data?.errors || "Something went wrong"
          );
        }

        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      navigate("/login");
    }
  };

  console.log(payload, "hugyygy");

  return (
    <>
      <div className="relative pb-8 lg:py-0 bg-slate-100 -mb-20">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="capitalize text-org-theme text-3xl pl-3 border-l-2 border-org-theme my-4">
            {"Check out"}
          </div>
          <div className="flex-col-reverse lg:flex-row flex gap-10">
            <div className="w-full lg:w-2/3 space-y-5 relative z-0 px-5 lg:pr-10 py-10">
              <div className="absolute right-0 top-0 bg-white h-full w-[100%] lg:w-[500%] z-[-1]"></div>
              <BillingDetails
                title={"Billing Address"}
                buttonTitle={"billing"}
                billingAddressChange={(val) => {
                  if (val !== null) {
                    setPayload((pre) => ({ ...pre, billingAddress: val }));
                  }
                }}
              />

              <ShippingAddress
                shippingAddress={"Shipping Address"}
                shippingDescription={
                  "Select the address that matches your card or payment method."
                }
                shippingChange={(val) => {
                  setPayload((prevState) => ({
                    ...prevState,
                    isShippingAddressSame: val === "same" ? true : false,
                    isMailingAddressSame: val === "same" ? true : false,
                    shippingAddress: val === "same" ? {} : val,
                  }));
                }}
              />
              <div className="text-2xl text-slate-900 tracking-wider mb-3">
                Payment Method
              </div>
              {/* <div className="text-base text-slate-900 ">
                Your selected delivery method will apply to all items in this
                order.
              </div> */}
              <div className="w-full bg-slate-50 rounded-xl sm:px-0 pb-5 mt-5">
                <div className={`relative divide-slate-200`}>
                  <div className="relative py-3 flex flex-col gap-4 px-1">
                    <div className="relative py-3">
                      <Radio
                        radioLabel={"Cash on delivery"}
                        radioLableClass={"!font-bold text-lg"}
                        radioName={"paymentMethod"}
                        divClass={"!flex-row"}
                        onChange={(e) => setPaymentMethod(e)}
                        value={"same"}
                        checked={paymentMethod}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-wrap flex md:flex my-5 items-center justify-center sm:justify-between ">
                <div className="relative">
                  <Button
                    buttonLabel={"Return to cart"}
                    buttonIconPosition={"left"}
                    buttonIcon={"fa-fw fa-regular fa-bag-shopping"}
                    buttonLabelClasses={"text-md !font-normal capitalize"}
                    buttonClasses={
                      "!h-12 !px-4 !border-0 hover:!bg-black transition-all duration-300"
                    }
                    buttonHasLink={true}
                    buttonLink={"/cart"}
                  />
                </div>
                {payload?.items?.length > 0 && payload?.totalPrice > 0 && (
                  <div className="flex flex-wrap sm:flex justify-center items-center gap-3 mt-4 md:mt-0 lg:mt-4 xl:mt-0">
                    <Button
                      isDisable={loaded}
                      buttonClasses={
                        "!h-12 !px-5 !border-0 !text-white hover:!bg-black transition-all duration-300"
                      }
                      buttonLabelClasses={"!text-md !font-normal capitalize"}
                      buttonIcon={"fa-fw fa-regular fa-bag-shopping"}
                      buttonIconPosition={"left"}
                      buttonType={"button"}
                      buttonFunction={validateAndNavigate}
                      //
                      buttonLabel={loaded ? "Please Wait" : "Place Order"}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="w-full lg:w-1/3 space-y-5 relative">
              <div className="py-5">
                <OrderSummery
                  payload={payload}
                  orderSummary={"Order Summary"}
                  itemsData={(data) => {
                    setPayload((pre) => ({
                      ...pre,
                      items: data?.items,
                      totalPrice: parseFloat(data?.totalPrice),
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
