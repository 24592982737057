import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../form/Button";

const ContactDetails = () => {
  const redirectPage = (e) => {
    window.open(`${e}`, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="flex xl:w-1/2 w-full justify-center md:px-10 px-5 md:py-5 py-2">
      <div className="relative overflow-hidden flex flex-col">
        <div className="md:text-4xl text-xl font-bold text-org-theme ">
          How Can We Help You?
        </div>
        <div className="md:text-lg text-sm font-semibold md:pt-5 pt-2 text-gray-500">
          Not sure what you need? The team at square Events will be happy to
          listen to you and suggest event ideas you hadn't consider
        </div>
        <div className="xl:pt-14 md:pt-10 pt-5 md:space-y-3 space-y-1">
          <Link
            to="mailto:inforunicorn@gmail.com"
            className="flex items-center space-x-5 text-lg "
          >
            <div className="text-org-theme">
              <i className="fa-solid fa-envelope"></i>
            </div>
            <div className="font-semibold md:text-lg text-sm">
            inforunicorn@gmail.com
            </div>
          </Link>
          <a
            href="tel:8902070013"
            className="flex items-center space-x-5 text-lg"
          >
            <div>
              <div className="text-org-theme">
                <i className="fa-solid fa-phone"></i>
              </div>
            </div>
            <div className="font-semibold  md:text-lg text-sm">
              Support:<span className="font-medium pl-2">8902070013</span>
            </div>
          </a>
        </div>
        <div className=" md:mt-5 mt-5">
          <div className="md:text-lg text-sm font-bold xl-pt-0 md:pt-8   pt-0 md:pb-5 pb-2 text-org-theme">
            Follow Us On :
          </div>
          <div className="flex md:space-x-6 space-x-4 text-2xl text-white">
            <Button
              buttonClasses={
                "!bg-slate-900 md:w-12 md:h-12 w-8 h-8 !rounded-full bg-black hover:!bg-org-theme flex justify-center items-center"
              }
              buttonIcon={"fa-brands fa-facebook-f"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => redirectPage("/")}
            />
            <Button
              buttonClasses={
                "!bg-slate-900 md:w-12 md:h-12 w-8 h-8 !rounded-full bg-black hover:!bg-org-theme flex justify-center items-center"
              }
              buttonIcon={"fa-brands fa-instagram"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => redirectPage("/")}
            />
            <Button
              buttonClasses={
                "!bg-slate-900 md:w-12 md:h-12 w-8 h-8 !rounded-full bg-black hover:!bg-org-theme flex justify-center items-center"
              }
              buttonIcon={"fa-brands fa-twitter"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => redirectPage("/")}
            />
            {/* <div className=" w-12 h-12 rounded-full bg-black hover:bg-red-600 flex justify-center items-center"><i className="fa-brands fa-facebook-f"></i></div>
            <div className=" w-12 h-12 rounded-full bg-black hover:bg-red-600 flex justify-center items-center"><i className="fa-brands fa-instagram"></i></div>
            <div className=" w-12 h-12 rounded-full bg-black hover:bg-red-600 flex justify-center items-center"><i className="fa-brands fa-twitter"></i></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
