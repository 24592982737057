import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import slide1 from "../../assets/img/slider/slide1.jpg";
import slide2 from "../../assets/img/slider/slide2.jpg";
import slide3 from "../../assets/img/slider/slide3.png";
import bannerimg1 from "../../assets/img/banner-1-text.png";
import bannerimg2 from "../../assets/img/banner-2-text.png";
import bannerimg3 from "../../assets/img/banner-3-text.png";

const SliderComponent = () => {
  return (
    <div className="slider_area slider_style">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{ delay: 1000 }}
        speed={1000}
        pagination={{ clickable: true }}
      >
        <SwiperSlide>
          <div
            className="single_slider bg-center bg-no-repeat bg-cover overflow-hidden pt-44 md:h-screen"
            style={{ backgroundImage: `url(${slide1})` }}
          >
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="absolute transform -translate-y-1/2 top-1/2 left-[10%]">
                  {/* <div className="slider_content content_one text-center">
                    <img src={bannerimg3} alt="Slider Content" />
                    <p>the wooboom spring collection is back at half price</p>
                    <a href="product-listing.html" className="btn">
                      Discover Now
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="single_slider bg-center bg-no-repeat bg-cover overflow-hidden pt-44 md:h-screen"
            style={{ backgroundImage: `url(${slide2})` }}
          >
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="absolute transform -translate-y-1/2 top-1/2 left-[10%]">
                  <div className="slider_content content_two text-center md:pr-0 pr-8">
                    <img src={bannerimg1} alt="Slider Content" />
                    <p>the wooboom jacket collection is back at half price</p>
                    <a href="product-listing.html" className="btn">
                      Discover Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="single_slider bg-center bg-no-repeat bg-cover overflow-hidden pt-44 md:h-screen"
            style={{ backgroundImage: `url(${slide3})` }}
          >
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-12">
                  <div className="slider_content content_three text-center md:px-0 px-8">
                    <img src={bannerimg2} alt="Slider Content" />
                    <p>
                      the wooboom clothing summer collection is back at half
                      price
                    </p>
                    <a href="product-listing.html" className="btn">
                      Discover Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SliderComponent;
