import React from "react";

const TermsAndCondition = () => {
  return (
    <div className="bg-gray-100 py-2 h-full">
      <div className="container mx-auto px-4">
        <div className="bg-white shadow-lg rounded-lg p-4 md:p-8 lg:p-12 max-w-6xl mx-auto">
          <h1 className="text-3xl font-bold mb-4 md:text-4xl text-org-theme">
            Terms & Conditions
          </h1>

          <div className="relative">
            {/* <p className="mb-4 text-lg leading-relaxed">Last Updated: [Date]</p> */}

            <p className="mb-4 text-lg leading-relaxed">
              Welcome to Unicron Cloths! These Terms and Conditions govern your
              use of our website and services. By accessing or using our site,
              you agree to be bound by these terms. If you do not agree, please
              do not use our site.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              1. Acceptance of Terms
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              By using our website, you confirm that you are at least 18 years
              old or have the consent of a parent or guardian.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              2. Product Information
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              We strive to provide accurate product descriptions and pricing.
              However, we cannot guarantee that all information is error-free.
              Prices and availability are subject to change without notice.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              3. Ordering and Payment
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              <strong>Placing an Order:</strong> By placing an order, you make
              an offer to purchase the selected products. We will confirm
              receipt of your order via email.
            </p>
            <p className="mb-4 text-lg leading-relaxed">
              <strong>Payment Methods:</strong> We accept various payment
              methods including credit cards, PayPal, and other secure payment
              options.
            </p>
            <p className="mb-4 text-lg leading-relaxed">
              <strong>Order Cancellation:</strong> You may cancel your order
              before it has been processed. Once processed, cancellations will
              be subject to our return policy.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              4. Shipping and Delivery
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              <strong>Shipping Costs:</strong> Shipping costs will be calculated
              at checkout.
            </p>
            <p className="mb-4 text-lg leading-relaxed">
              <strong>Delivery Times:</strong> We aim to deliver products within
              the estimated timeframes. However, delays may occur due to
              unforeseen circumstances.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              5. Returns and Refunds
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              <strong>Return Policy:</strong> We accept returns within 5 days of
              purchase. Products must be unused and in original packaging.
            </p>
            <p className="mb-4 text-lg leading-relaxed">
              <strong>Refunds:</strong> Once we receive your return, we will
              process your refund within 7-14 business days.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              6. User Accounts
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              If you create an account, you are responsible for maintaining the
              confidentiality of your account information and for all activities
              under your account. Notify us immediately of any unauthorized use.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              7. Intellectual Property
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              All content on our site, including text, graphics, logos, and
              images, is the property of [Your Store Name] and is protected by
              copyright and trademark laws.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              8. Limitation of Liability
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              To the fullest extent permitted by law, [Your Store Name] is not
              liable for any indirect, incidental, or consequential damages
              arising from your use of our site or products.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              9. Governing Law
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              These terms are governed by the laws of India. Any disputes will
              be resolved in the courts of India.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              10. Changes to Terms
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              We reserve the right to modify these terms at any time. Changes
              will be effective immediately upon posting on our site. Your
              continued use constitutes acceptance of the updated terms.
            </p>

            <h2 className="text-2xl font-bold mb-2 md:text-3xl">
              11. Contact Us
            </h2>
            <p className="mb-4 text-lg leading-relaxed">
              If you have any questions about these Terms and Conditions, please
              contact us at 8902070013 or .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
