import React from "react";
import method from "../assets/img/methods.png";

const DefaultFooter = () => {
  return (
    <>
      <footer className="">
        <div className="pt-10 !pb-0 text-white">
          <div className="w-full h-full">
            <div className="relative ">
              <div className="xl:w-100vh w-full  xl:h-[512px] lg:h-[700px] md:h-[775px] h-[1180px] overflow-hidden absolute z-[-1]">
                <img
                  src="/static/media/banner11.7ee422b126a3036dbb7a.jpg"
                  alt=""
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-black !bg-opacity-60"></div>
              </div>
              <div className="mx-auto px-0 w-full px-4 sm:px-0 sm:max-w-2xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto xl:py-20 sm:py-5 py-0 pt-4">
                <div className="xl:flex justify-between block 2xl:gap-20 gap-10 !justify-center xl:space-y-0 space-y-8 ">
                  <div className="lg:w-1/2 md:w-1/2 w-full">
                    <div className="widgets_container newsletter 2xl:pr-24 pr-16">
                      <a href="#">
                        <img
                          src="/static/media/unicorn.a4a52819f9aacfd5d06646cde681bf49.svg"
                          alt=""
                          className="w-1/2 mb-4"
                        />
                      </a>
                      <div className="newsletter-content">
                        <p>
                          Exceptional quality. Ethical factories. Sign up to
                          enjoy free U.S. shipping and returns on your first
                          order.
                        </p>
                        <p className="mt-3">
                          Exceptional quality. Ethical factories. Sign up to
                          enjoy free U.S. shipping and returns on your first
                          order.
                        </p>
                      </div>
                      <div className="cardarea mt-4">
                        <b>We accept:</b>{" "}
                        <img src={method} alt="" className="mt-2" />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex block xl:gap-14 gap-10 w-full">
                    <div className="lg:w-3/12 md:w-1/3 w-1/2">
                      <div className="widgets_container !text-white">
                        <h3 className="text-xl mb-4 !text-white">
                          Information
                        </h3>
                        <div className="footer_menu">
                          <ul>
                            <li>
                              <a href="/" className="block py-1 !text-white">
                                About Us
                              </a>
                            </li>
                            <li>
                              <a href="/" className="block py-1 !text-white">
                                FAQ
                              </a>
                            </li>
                            <li>
                              <a href="/" className="block py-1 !text-white">
                                Delivery Information
                              </a>
                            </li>
                            <li>
                              <a
                                href="/contact-us"
                                className="block py-1 !text-white"
                              >
                                Contact Us
                              </a>
                            </li>
                            <li>
                              <a href="/" className="block py-1 !text-white">
                                Privacy Policy
                              </a>
                            </li>
                            <li>
                              <a href="/" className="block py-1 !text-white">
                                Exchange & Return Policy
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="lg:w-3/12 md:w-1/3 w-1/2">
                      <div className="widgets_container">
                        <h3 className="text-xl mb-4 !text-white">Extras</h3>
                        <div className="footer_menu">
                          <ul>
                            <li>
                              <a
                                href="/wishlist"
                                className="block py-1 !text-white"
                              >
                                Wishlist
                              </a>
                            </li>
                            <li>
                              <a
                                href="/login"
                                className="block py-1 !text-white"
                              >
                                Login
                              </a>
                            </li>
                            <li>
                              <a
                                href="/orders"
                                className="block py-1 !text-white"
                              >
                                Order History
                              </a>
                            </li>
                            <li>
                              <a
                                href="/products"
                                className="block py-1 !text-white"
                              >
                                Gift Certificates
                              </a>
                            </li>
                            <li>
                              <a
                                href="/products"
                                className="block py-1 !text-white"
                              >
                                Anime
                              </a>
                            </li>
                            <li>
                              <a
                                href="/products"
                                className="block py-1 !text-white"
                              >
                                overized t shirt
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-3/12 md:w-1/2 w-full">
                      <div className="widgets_container contact_us">
                        <h3 className="text-xl mb-4 !text-white">Contact Us</h3>
                        <div className="footer_contact">
                          <p>Address: West Bengal, Howrah, </p>
                          <p>
                            P. S - Domjur Prosastha sardar para, satbigha mat,
                            711302
                          </p>

                          <p>
                            Phone:{" "}
                            <a href="tel:8902070013" className="text-white">
                              8902070013
                            </a>{" "}
                          </p>
                          <p>
                            Email:{" "}
                            <a
                              href="mailto: inforunicorn@gmail.com"
                              className="text-white"
                            >
                              inforunicorn@gmail.com
                            </a>
                          </p>
                          <ul className="flex space-x-4 mt-4">
                            <li>
                              <a
                                href="#"
                                title="facebook"
                                className="text-blue-600"
                              >
                                <i class="fa-brands fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="instagram"
                                className="text-pink-600"
                              >
                                <i class="fa-brands fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                title="youtube"
                                className="text-red-600"
                              >
                                <i class="fa-brands fa-youtube"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className=" flex items-center"></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom py-4 bg-org-theme text-white">
          <div className="mx-auto px-0 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
            <div className="md:flex block">
              <div className="lg:w-1/2 w-full">
                <div className="copyright_area !text-black">
                  <p>
                    {" "}
                    &copy; 2024 <strong>Unicorn</strong> All Rights Reserved
                  </p>
                </div>
              </div>
              <div className="lg:w-1/2 w-full">
                <div className="footer_custom_links">
                  <ul className="flex space-x-4 md:justify-end justify-center">
                    <li>
                      <a
                        href="/terms-and-conditions"
                        className="hover:underline !text-black"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a
                        href="/privacy-policy"
                        className="hover:underline !text-black"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href="/shipping-policy"
                        className="hover:underline !text-black"
                      >
                        Shipping Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default DefaultFooter;
