import React from "react";

const UpperFooter = () => {
  return (
    <>
      <div className="mx-auto px-0 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-autos !mt-14">
        {/* <upperFooter/> */}
        {
          <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 xl:gap-8 gap-6 mb-20">
            <div className="border border-slate-300 p-10 flex flex-col justify-center items-center text-center">
              <div className="text-4xl text-slate-500">
                <i class="fa-thin fa-truck"></i>
              </div>
              <div className="text-xl font-bold text-slate-800 mt-4">
                Free Shipping
              </div>
              <div className="text-base text-slate-600 mt-3">
                Free shipping on all US order or order above $200
              </div>
            </div>
            <div className="border border-slate-300 p-10 flex flex-col justify-center items-center text-center">
              <div className="text-4xl text-slate-500">
                <i class="fa-thin fa-hand-holding-seedling"></i>
              </div>
              <div className="text-xl font-bold text-slate-800 mt-4">
                24X7 Support
              </div>
              <div className="text-base text-slate-600 mt-3">
                Contact us 24 hours a day, 7 days a week
              </div>
            </div>
            <div className="border border-slate-300 p-10 flex flex-col justify-center items-center text-center">
              <div className="text-4xl text-slate-500">
                <i class="fa-thin fa-badge-percent"></i>
              </div>
              <div className="text-xl font-bold text-slate-800 mt-4">
                30 Days Return
              </div>
              <div className="text-base text-slate-600 mt-3">
                Simply return it within 30 days for an exchange
              </div>
            </div>
            <div className="border border-slate-300 p-10 flex flex-col justify-center items-center text-center">
              <div className="text-4xl text-slate-500">
                <i class="fa-light fa-circle-dollar-to-slot"></i>
              </div>
              <div className="text-xl font-bold text-slate-800 mt-4">
                Payment Secure
              </div>
              <div className="text-base text-slate-600 mt-3">
                Contact us 24 hours a day, 7 days a week
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default UpperFooter;
