import React from "react";
import img from "../../assets/img/blog/blog1.jpg";
import img2 from "../../assets/img/blog/blog2.png";
import img3 from "../../assets/img/blog/blog8.jpg";
import img4 from "../../assets/img/blog/blog3.png";
import img5 from "../../assets/img/blog/blog4.png";
import img6 from "../../assets/img/blog/blog6.jpg";
import { Link } from "react-router-dom";

const BlogSection = () => {
  return (
    <>
      <div className="mx-auto px-0 w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-autos">
        <div className="uppercase xl:text-5xl md:text-3xl text-2xl  text-black font-bold flex  border-b !border-org-theme md:pb-3 pb-1">
          Blog
        </div>
        <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 mt-10">
          <div>
            <div className="rounded-t-xl w-full h-[300px] overflow-hidden">
              <img src={img} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="bg-org-theme/10 border border-slate-200 rounded-b-xl  px-8 py-4 shadow-lg">
              <div className="text-5xl text-slte-600 mt-2">
                10<span className="text-xl">/dec</span>
              </div>
              <div className="text-org-theme text-2xl font-bold mt-2">
                Format ectus formentum
              </div>
              <div className="text-sm text-slte-400">
                lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </div>
              <div className="flex my-2"></div>
              <div className="flex">Posted by admin</div>
            </div>
          </div>

          <div>
            <div className="rounded-t-xl w-full h-[300px] overflow-hidden">
              <img src={img2} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="bg-org-theme/10 border border-slate-200 rounded-b-xl  px-8 py-4  shadow-lg">
              <div className="text-5xl text-slte-600 mt-2">
                12<span className="text-xl">/sep</span>
              </div>
              <div className="text-org-theme text-2xl font-bold mt-2">
                Format ectus formentum
              </div>
              <div className="text-sm text-slte-400">
                lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </div>
              <div className="flex my-2">
                {/* <Link className=" border-b border-org-theme text-org-theme text-base font-bold">
                  read more
                </Link> */}
              </div>
              <div className="flex">Posted by admin</div>
            </div>
          </div>

          <div>
            <div className="rounded-t-xl w-full h-[300px] overflow-hidden">
              <img src={img3} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="bg-org-theme/10 border border-slate-200 rounded-b-xl  px-8 py-4  shadow-lg">
              <div className="text-5xl text-slte-600 mt-2">
                15<span className="text-xl">/Aug</span>
              </div>
              <div className="text-org-theme text-2xl font-bold mt-2">
                Format ectus formentum
              </div>
              <div className="text-sm text-slte-400">
                lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </div>
              <div className="flex my-2"></div>
              <div className="flex">Posted by admin</div>
            </div>
          </div>
          <div>
            <div className="rounded-t-xl w-full h-[300px] overflow-hidden">
              <img src={img4} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="bg-org-theme/10 border border-slate-200 rounded-b-xl  px-8 py-4  shadow-lg">
              <div className="text-5xl text-slte-600 mt-2">
                30<span className="text-xl">/jan</span>
              </div>
              <div className="text-org-theme text-2xl font-bold mt-2">
                Format ectus formentum
              </div>
              <div className="text-sm text-slte-400">
                lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </div>
              <div className="flex my-2"></div>
              <div className="flex">Posted by admin</div>
            </div>
          </div>
          <div>
            <div className="rounded-t-xl w-full h-[300px] overflow-hidden">
              <img src={img5} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="bg-org-theme/10 border border-slate-200 rounded-b-xl  px-8 py-4 shadow-lg ">
              <div className="text-5xl text-slte-600 mt-2">
                10<span className="text-xl">/dec</span>
              </div>
              <div className="text-org-theme text-2xl font-bold mt-2">
                Format ectus formentum
              </div>
              <div className="text-sm text-slte-400">
                lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </div>
              <div className="flex my-2"></div>
              <div className="flex">Posted by admin</div>
            </div>
          </div>
          <div>
            <div className="rounded-t-xl w-full h-[300px] overflow-hidden">
              <img src={img6} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="bg-org-theme/10 border border-slate-200 rounded-b-xl  px-8 py-4 shadow-lg ">
              <div className="text-5xl text-slte-600 mt-2">
                10<span className="text-xl">/dec</span>
              </div>
              <div className="text-org-theme text-2xl font-bold mt-2">
                Format ectus formentum
              </div>
              <div className="text-sm text-slte-400">
                lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,{" "}
              </div>
              <div className="flex my-2"></div>
              <div className="flex">Posted by admin</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogSection;
