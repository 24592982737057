import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Categories from "../components/home/Categories";
import TopSellingItems from "../components/home/TopSellingItems";
import BannerSection from "../components/home/BannerSection";
import TrendingProducts from "../components/home/TrendingProducts";
import PopularProducts from "../components/home/PopularProducts";
import SliderComponent from "../components/home/SliderComponent";
import OfferDiscountBanner from "../components/home/OfferDiscountBanner";
import UpperFooter from "../components/sections/upperFooter";

const Home = () => {
  setTitle("Unicron Cloths");

  return (
    <>
      <SliderComponent />
      <Categories />
      <TopSellingItems />
      <BannerSection />
      <TrendingProducts />
      <PopularProducts />
      <OfferDiscountBanner />
      <UpperFooter />
    </>
  );
};

export default Home;
