import React, { useState } from "react";
import Input from "../form/Input";
import TextAreaAutoSize from "../form/TextAreaAutoSize";
import { formValidate } from "../../helpers/formValidate";
import useForm from "../../hooks/useForm";
import Button from "../form/Button";
import { toast } from "react-toastify";
import { addContactQuery } from "../../services/ContactService";

const ContactInfoItem = () => {
  const validation = {
    name: { required: true, message: "Please enter your  name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phone: { required: true, message: "Please enter your phone !!!" },
  };
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);
  const [message, setMessage] = useState("");
  const [loaded, setLoaded] = useState(false);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addContactQuery({
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        message: message,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.success(res?.data?.message);
        resetField({ name: "", email: "", phone: "" });
        setMessage("");
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <div className="flex xl:w-1/2 w-full relative md:px-0 px-5">
        {/* <div className="absolute bottom-0 lg:-top-10 left-0 aspect-[16/10]  w-full overflow-hidden">
          <Image
            src={ContactUsImage}
            alt=""
            effect="blur"
            className="object-contain object-top w-full h-full"
          />
        </div> */}

        <div className="relative w-full md:px-10 px-6 bg-gray-100 shadow-[0_5px_10px_0px_rgb(0,0,0,0.3)] py-8 rounded-md md:mt-0 mt-6">
          <div className="w-full mx-auto max-w-5xl px-0  md:space-y-4 space-y-2">
            <div className="md:text-3xl text-2xl font-semibold">
              Get{" "}
              <span className="text-org-theme border-b border-slate-400 pb-1">
                In Touch
              </span>
            </div>
            <div className="md:text-lg text-sm text-slate-600">
              Feel free to drop us a line below
            </div>
            <div className="relative space-y-4 pt-5">
              <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
                <div className="mb-3">
                  <Input
                    isInputGroup={true}
                    label={" Name"}
                    labelClasses={"!text-base"}
                    inputType={"text"}
                    value={values.name}
                    inputName={"name"}
                    onChange={handleChange}
                    inputPlaceholder={"Enter Your Name"}
                    {...formValidate(errors, "name")}
                  />
                </div>

                <div className="grid sm:grid-cols-2 gap-4">
                  <div className="mb-3">
                    <Input
                      isInputGroup={true}
                      label={"Phone"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.phone}
                      inputName={"phone"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Phone"}
                      {...formValidate(errors, "phone")}
                    />
                  </div>
                  <div className="mb-3">
                    <Input
                      isInputGroup={true}
                      label={"Email"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.email}
                      inputName={"email"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Email"}
                      {...formValidate(errors, "email")}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <TextAreaAutoSize
                    label={"Message"}
                    labelClasses={"!text-base"}
                    inputValue={message}
                    inputName={"message"}
                    onChange={(e) => setMessage(e.target.value)}
                    inputPlaceholder={"Enter Message"}
                  />
                </div>
                <div className="mb-3">
                  <Button
                    buttonHasLink={false}
                    buttonType={"submit"}
                    buttonClasses={"!w-full"}
                    buttonLabel={"Submit"}
                    isDisable={loaded}
                    buttonLabelClasses={"uppercase font-semibold !text-sm"}
                    buttonEffect={"filled"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfoItem;
