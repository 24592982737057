import React from "react";
const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 py-8 h-full">
      <div className="container mx-auto px-4">
        <div className="bg-white shadow-lg rounded-lg p-6 md:p-10 lg:p-14 max-w-6xl mx-auto">
          <h1 className="text-3xl font-bold mb-6 md:text-4xl text-org-theme">
            Privacy Policy
          </h1>

          {/* <p className="text-lg leading-relaxed mb-6">
            Effective Date: [Insert Date]
          </p> */}

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            1. Introduction
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            Welcome to Unicron Cloths. Your privacy is important to us. This
            Privacy Policy outlines how we collect, use, disclose, and protect
            your personal information when you visit our website
            https://unicorncloths.com/ and make purchases from our store.
          </p>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            2. Information We Collect
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            We collect various types of information to provide and improve our
            services to you:
          </p>
          <ul className="list-disc list-inside mb-6 text-lg leading-relaxed">
            <li>
              <strong>Personal Information:</strong> When you create an account,
              make a purchase, or contact us, we may collect personal
              information such as your name, email address, phone number,
              billing address, and shipping address.
            </li>
            <li>
              <strong>Payment Information:</strong> We may collect payment
              information, including credit card numbers, through our payment
              processor. We do not store this information on our servers.
            </li>
            <li>
              <strong>Usage Data:</strong> We may collect information about how
              you access and use our website, including your IP address, browser
              type, pages visited, and time spent on the site.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            3. How We Use Your Information
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            We use your information for various purposes, including:
          </p>
          <ul className="list-disc list-inside mb-6 text-lg leading-relaxed">
            <li>To process and fulfill your orders.</li>
            <li>
              To communicate with you about your orders and respond to your
              inquiries.
            </li>
            <li>To personalize your shopping experience.</li>
            <li>To improve our website and services.</li>
            <li>To send you promotional materials, with your consent.</li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            4. Information Sharing
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            We do not sell or rent your personal information to third parties.
            We may share your information in the following circumstances:
          </p>
          <ul className="list-disc list-inside mb-6 text-lg leading-relaxed">
            <li>
              <strong>Service Providers:</strong> We may share your information
              with third-party service providers who assist us in operating our
              website, processing payments, or delivering products.
            </li>
            <li>
              <strong>Legal Compliance:</strong> We may disclose your
              information if required by law or to protect our rights.
            </li>
          </ul>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            5. Cookies and Tracking Technologies
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            Our website uses cookies and similar technologies to enhance your
            experience. You can manage your cookie preferences through your
            browser settings. For more details, please refer to our Cookie
            Policy.
          </p>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            6. Security of Your Information
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            We take reasonable measures to protect your personal information
            from unauthorized access and disclosure. However, no method of
            transmission over the internet or method of electronic storage is
            100% secure.
          </p>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            7. Your Rights
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            You have certain rights regarding your personal information,
            including the right to access, correct, or delete your data. If you
            wish to exercise these rights, please contact us at
            inforunicorn@gmail.com .
          </p>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">
            8. Changes to This Privacy Policy
          </h2>
          <p className="text-lg leading-relaxed mb-6">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new policy on this page with a
            revised effective date.
          </p>

          <h2 className="text-2xl font-bold mb-4 md:text-3xl">9. Contact Us</h2>
          <p className="text-lg leading-relaxed mb-4">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p className="text-lg leading-relaxed">
            Unicron Cloths <br />
            inforunicorn@gmail.com <br />
            8902070013 <br />
            Domjur Prosastha sardar para, satbigha mat, West Bengal ,
            Howrah,711302
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
