import React, { useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";

import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";

import Logo from "../assets/images/logo.png";
import PlayStore from "../assets/images/play-store.png";
import AppStore from "../assets/images/app-store.png";
import { apiService } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { getCartListApi } from "../redux/slice/cartSlice";
import { setIsLogin } from "../redux/slice/authSlice";
import { userVerifyTokan } from "../services/loginService";
import { getProfileDetails } from "../services/profilrService";
import { setProfileDetails } from "../redux/slice/profileSlice";
import { cartListData } from "../services/cartService";
import { setCartDetails } from "../redux/slice/cartTotalSlice";

const DefaultLayout = ({ isMenuData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({});
  const isLogin = useSelector((state) => state?.auth?.isLogin);
  const { data: cartLists, isLoaded: cartLoaded } = useSelector(
    (state) => state?.cart?.cartData
  );
  const { roleDetails } = useSelector((state) => state.profile.profileData);
  const authToken = localStorage.getItem("auth_token");
  const totalCartData = useSelector((state) => state?.cartTotal?.cartListTotal);

  useEffect(() => {
    if (authToken && roleDetails?.code == "USER") {
      dispatch(setIsLogin(true));
    } else {
      dispatch(setIsLogin(false));
    }
  }, [authToken, roleDetails?.code]);

  const loadVerifyToken = useCallback(async () => {
    if (authToken) {
      try {
        const res = await userVerifyTokan();
        if (res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");
          dispatch(setIsLogin(false));
        } else {
          dispatch(setIsLogin(true));
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");
          dispatch(setIsLogin(false));
        } else {
          console.error("Unexpected error:", error);
          localStorage.removeItem("auth_token");
          navigate("/");
          dispatch(setIsLogin(false));
        }
      }
    }
  }, []);

  useEffect(() => {
    loadVerifyToken();
  }, [loadVerifyToken, authToken]);

  const menuData = {
    logo: Logo,
    menu: [
      { _id: 1, name: "Home", link: "/" },
      { _id: 2, name: "About Us", link: "/" },
      { _id: 3, name: "FAQ's", link: "/" },
      { _id: 4, name: "Contact Us", link: "/" },
    ],
    appDownload: {
      menu: [
        { _id: 1, name: "Play Store", link: "/", image: PlayStore },
        { _id: 2, name: "App Store", link: "/", image: AppStore },
      ],
    },
  };

  const footerData = {
    logo: Logo,
    footerMenu: {
      title: "Quick Links",
      menu: [
        { _id: 1, name: "Home", link: "/" },
        { _id: 2, name: "About Us", link: "/" },
        { _id: 4, name: "FAQ's", link: "/" },
        { _id: 5, name: "Contact Us", link: "/contact-us" },
      ],
    },
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: "inforunicorn@gmail.com",
          icon: "fa-envelope",
          type: "mailto",
        },
        {
          _id: 2,
          label: "Contact No.",
          data: settings?.phone,
          icon: "fa-phone-volume",
          type: "tel",
        },
        {
          _id: 3,
          label: "Address",
          data: settings?.address,
          icon: "fa-location-dot",
          type: "address",
        },
      ],
    },
    footerDownload: {
      title: "App Download",
      menu: [
        {
          _id: 1,
          name: "Play Store",
          link: settings?.social?.playstore,
          image: PlayStore,
        },
        {
          _id: 2,
          name: "App Store",
          link: settings?.social?.appstore,
          image: AppStore,
        },
      ],
    },
    socialMenuTitle: "Reach us at",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: settings?.social?.facebook,
        icon: "fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: settings?.social?.instagram,
        icon: "fa-instagram",
      },
      {
        _id: 3,
        name: "Twitter",
        link: settings?.social?.twitter,
        icon: "fa-x-twitter",
      },
    ],
    copyright: "Copyright © 2024 ServiceFlows | All Rights Reserved",
  };

  const getCartListTotal = useCallback(() => {
    cartListData().then((res) => {
      if (res?.status === 200) {
        dispatch(setCartDetails(res?.cartSummary));
      } else {
        dispatch(setCartDetails({}));
      }
    });
  }, []);

  useEffect(() => {
    if (isLogin) {
      dispatch(getCartListApi({}));
      getCartListTotal();
    }
  }, [dispatch, cartLists?.length === 0, roleDetails, isLogin]);

  //profile details
  const getDetails = useCallback(() => {
    getProfileDetails().then((res) => {
      if (res?.status === 200) {
        dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
      } else {
        dispatch(setProfileDetails({}));
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <>
      <DefaultHeader data={menuData} isMenuData={isMenuData} totalCartData={totalCartData}/>
      <ScrollSpy
        scrollThrottle={100}
        useBoxMethod={false}
        updateHistoryStack={false}
        activeClass="active"
      >
        <Outlet />
      </ScrollSpy>
      <DefaultFooter data={footerData} settings={settings} />
    </>
  );
};

export default DefaultLayout;
