import React from "react";

const ShippingPolicy = () => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-gray-100 py-8 min-h-screen">
      <div className="container mx-auto px-6 lg:px-12">
        <div className="bg-white shadow-2xl rounded-lg p-8 md:p-12 lg:p-16 max-w-4xl mx-auto relative">
          {/* Decorative Top Border */}
          <div className="absolute -top-5 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-blue-600 to-teal-500 w-48 h-2 rounded-full"></div>

          {/* Icon and Title */}
          <div className="text-center">
            <div className="inline-block bg-gradient-to-r from-blue-600 to-teal-500 text-white p-4 rounded-full shadow-lg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8c3.333 0 4-2 4-4 0-1.5-.5-2-1-2M12 8c-3.333 0-4-2-4-4 0-1.5.5-2 1-2m4 4v6M8 8v6M6 18l-3 3m0 0l-3-3m3 3V10m6 11l3 3m0 0l3-3m-3 3V10m-9-2l3-3m0 0l3 3m-3-3v4m6-4l3 3m0 0l3-3m-3 3v4M12 3v4m-3-2l3 3m0 0l3-3m-3 3V5"
                />
              </svg>
            </div>
            <h1 className="text-4xl font-bold text-gray-800 mt-4">
              Shipping Policy
            </h1>
            {/* <p className="text-gray-500 mt-2">
              Effective Date:{" "}
              <span className="font-semibold">[Insert Date]</span>
            </p> */}
          </div>

          {/* Shipping Policy Content */}
          <div className="mt-10 space-y-8 text-gray-700">
            <div>
              <h2 className="text-2xl font-semibold text-blue-600">
                Shipping Locations
              </h2>
              <p className="mt-2 text-lg">
                We currently ship to{" "}
                <span className="font-semibold">West Bengal, India</span>. If
                you are located outside these areas, please contact our customer
                service for assistance.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-blue-600">
                Processing Time
              </h2>
              <p className="mt-2 text-lg">
                All orders are processed within 1-3 business days. Orders are
                not shipped or delivered on weekends or holidays. If we
                experience a high volume of orders, processing times may be
                extended. We will notify you via email if there is a significant
                delay.
              </p>
              <p className="mt-2 text-lg">
                Shipping costs are calculated at checkout based on the weight of
                your order and your location.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-blue-600">
                Order Tracking
              </h2>
              <p className="mt-2 text-lg">
                Once your order has shipped, you will receive an email with a
                tracking number. You can track your order on our website or
                through the carrier’s tracking page.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-blue-600">
                International Shipping
              </h2>
              <p className="mt-2 text-lg">
                For international orders, customs fees and import taxes may
                apply. These fees are the responsibility of the customer and are
                not included in your order total. Please check your country’s
                regulations for additional information.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-blue-600">
                Delivery Issues
              </h2>
              <p className="mt-2 text-lg">
                If your package is lost or damaged during transit, please
                contact us within <span className="font-semibold">5 days</span>{" "}
                so we can assist you in resolving the issue.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-blue-600">
                Returns and Exchanges
              </h2>
              <p className="mt-2 text-lg">
                Please refer to our{" "}
                <a
                  href="#"
                  className="text-teal-500 hover:text-teal-600 underline"
                >
                  Returns & Exchanges Policy
                </a>{" "}
                for information on how to return or exchange your order.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-blue-600">
                Contact Us
              </h2>
              <p className="mt-2 text-lg">
                If you have any questions about our shipping policy, please
                contact our customer service team at{" "}
                <span className="font-semibold">
                inforunicorn@gmail.com or 8902070013
                </span>
                .
              </p>
            </div>
          </div>

          {/* Decorative Bottom Border */}
          <div className="absolute -bottom-5 left-1/2 transform -translate-x-1/2 bg-gradient-to-r from-blue-600 to-teal-500 w-48 h-2 rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
