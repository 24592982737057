import React from "react";
import SliderComponent from "../components/home/SliderComponent";
import BlogSection from "../components/sections/BlogSection";
import UpperFooter from "../components/sections/upperFooter";

const Blog = () => {
  return (
    <>
      <div>
        <SliderComponent />
        <BlogSection />
        <UpperFooter />
      </div>
    </>
  );
};

export default Blog;
